





import { defineComponent, onMounted } from '@vue/composition-api'
import createApp from '@shopify/app-bridge';
import { getSessionToken } from "@shopify/app-bridge-utils";

export default defineComponent({
    name: 'App',

    setup() {
        onMounted(async () => {
            const [host] = location.search.match(/([A-Z])\w+/) || [];
            console.log(host);
            const app = createApp({
                apiKey: '9977bc64604089c0257aa4f932ed1501',
                host
            });
    
            const sessionToken = await getSessionToken(app);
            console.log(sessionToken);
        });
    }
});
