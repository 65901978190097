import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import Layout from '../components/Layout/index.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        redirect: '/onboading'
    },
    {
        path: '/onboading',
        component: Layout,
        children: [
            {
                path: '',
                component: () => import('../views/Onboading/index.vue')
            }
        ]
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
